import { Component, OnInit, OnDestroy, OnChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { PostListingComponent } from '../component/post-listing/post-listing.component';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {

  @ViewChild('listing', {static: true}) listing: PostListingComponent;

  public typeTitle;
  public typeSubtitle;
  public type;
  public conf = {params : {service_type_detail : []}, system: {}};
  public location = {type: null};

  private paramsSubscription: Subscription;
  public page = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {
      this.dataService.getHelpdashConfiguration((cb) => {
        this.conf = cb;
        // tslint:disable-next-line:prefer-for-of
        for (let x = 0; x < this.conf.params.service_type_detail.length; x++) {
          if (typeof this.conf.params.service_type_detail[x].value !== 'object') {
            this.conf.params.service_type_detail[x].value = JSON.parse(this.conf.params.service_type_detail[x].value);
          }
        }

        console.log(this.conf);
      });
  }


  ionViewDidEnter() {
    if ( history.state.location ) {
      this.location = history.state.location;
      this.location.type = this.type;
    } else {
      this.dataService.getLastPostSearch((search) => {
        if (search) {
          this.location = search;
        }
      });
    }
    console.log(this.location, history.state.location);
  }

  ngOnInit() {
    this.paramsSubscription = this.activatedRoute.params.subscribe( params => {
      this.type = params.type;
    });
  }

  ionViewDidLeave() {
    this.paramsSubscription.unsubscribe();
  }

  setLocation(location) {
    this.location = location;
    console.log(location)
    this.listing.resetListing();
    this.listing.getCollection();
  }
}
