import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {

  @ViewChild('contactForm', { static: true }) contactForm: NgForm;

  public conf = {params : {support_subject: []}, system: {}};
  public data;
  public isLoading;
  public success;

  constructor(
    private dataService: DataService,
  ) {
    this.isLoading = false;
    this.success = false;
    this.data = {};
  }

  ngOnInit() {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
      this.reset();
    });
  }

  submit() {

    this.success = false;

    if (!this.contactForm.form.valid) {
      console.log(this.contactForm);
      return;
    }

    this.isLoading = true;

    this.dataService.post('/system/contact/insert', this.data, (results) => {
      this.isLoading = false;
      this.success = true;
      this.data = {};
      this.contactForm.resetForm();
    });
  }

  new() {
    this.reset();
    this.success = false;
  }

  reset() {
    if (this.conf.params.support_subject) {
      this.data.subject = this.conf.params.support_subject[0].value;
    }
  }
}
