import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  public conf = {params : {support_subject: []}, system: {}};

  constructor(
    private dataService: DataService
  ) {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
    });
  }

  ngOnInit() {

  }

}
