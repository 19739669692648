import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditorConfigService {

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '200px',
    minHeight: '150px',
    placeholder: 'Type something. Test the Editor... ヽ(^。^)丿',
    translate: 'no'
  };
}
