import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { MapComponent } from '../../components/map/map.component';

@Component({
  selector: 'app-af-location',
  templateUrl: './af-location.component.html',
  styleUrls: ['./af-location.component.scss'],
})

export class AfLocationComponent implements OnInit, OnDestroy {

  @ViewChild('f', {static: true}) f: NgForm;
  @ViewChild('map', {static: false}) map: MapComponent;

  private changeSubscription;
  public isLoading = false;
  public data;
  public title;
  // tslint:disable-next-line:max-line-length
  public address = {lot: null, address: null, city: null, postcode: null, country: null, state: null, latitude: null, longitude: null, radius: 5, token: null, uuid: null};
  private addressValidateSnapshot = {};
  private radiusSnapshot = 5;
  public countries = [];
  public states = [];
  public validated = false;
  public suggestion = {zipcode: null, city: null, state: null};

  @Input() set setData(dt: any) {
    if (dt.uuid) {
      this.data = dt;
      if (this.data.address) {
        this.address = this.data.address;
        this.validated = true;
        this.addressValidateSnapshot = this.buildAddressString(this.address);
        setTimeout(() => {
          this.map.initMap(this.address.latitude, this.address.longitude, true, true, this.address.radius);
        }, 1000);
      }

      switch (this.data.type) {
        case 'properties':
          this.title = 'Tell us the location of the property';
          break;
        case 'services':
          this.title = 'Tell us the location where you offer the services';
          break;
        case 'vacancies':
          this.title = 'Tell us the location of the vacancy';
          break;
      }
    }
  }

  constructor(private dataService: DataService, private router: Router) {

  }

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  ngOnInit() {
    this.getCountry();
    this.changeSubscription = this.f.valueChanges.subscribe(
      (data) => {
        if (this.validate && data.radius && this.radiusSnapshot !== data.radius) {
          this.radiusSnapshot = data.radius;
          if (this.map) {
            setTimeout(() => {
              this.map.drawRadius(this.radiusSnapshot);
            }, 100);
          }
        }

        if (this.addressValidateSnapshot && this.buildAddressString(data) === this.addressValidateSnapshot) {
          this.validated = true;
          return;
        }

        this.validated = false;
    });
  }

  getCountry() {
    const t = {token: null, country: this.address.country};
    this.dataService.post('/system/country', t, (cb) => {
      this.countries = cb;
      if (!this.address || !this.address.country) {
        this.address.country = cb[0].code;
      }
      this.getState();
    });
  }

  getState() {
    const t = {token: null, country: this.address.country};
    this.dataService.post('/system/state', t, (cb) => {
      this.states = cb;
      if (!this.address || !this.address.state) {
        this.address.state = cb[0].state;
      }
    });
  }

  buildAddressString(add) {
    return add.address + ',' + add.postcode + ' ' + add.city + ',' + add.country;
  }

  lc(s) {
    if (!s) {
      return '';
    }
    return s.toLowerCase();
  }

  validate() {

    if (this.f.invalid) {
      this.dataService.alert('All fields are required. Please Enter Accordingly.', 'danger');
      return;
    }

    this.suggestion = {zipcode: null, city: null, state: null};

    // tslint:disable-next-line:max-line-length
    const search = this.buildAddressString(this.address);
    this.addressValidateSnapshot = search;

    const t = {search, token : null};
    this.isLoading = true;
    this.dataService.post('/system/get/address/by/keyword', t, (cb) => {
      this.isLoading = false;
      this.suggestion = cb;

      console.log(cb);

      if (cb.latitude && cb.longitude) {
        this.validated = true;
        this.address.latitude = cb.latitude.toFixed(7);
        this.address.longitude = cb.longitude.toFixed(7);
        setTimeout(() => {
          this.map.removeAllPin();
          this.map.initMap(this.address.latitude, this.address.longitude, true, true, this.address.radius);
        }, 100);
        return;
      }
      this.dataService.alert('Could not verify your address. Please ensure all address are enter correctly.', 'danger');
    });
  }

  draggedCoordination(cb) {
    this.address.latitude = cb.latitude.toFixed(7);
    this.address.longitude = cb.longitude.toFixed(7);
    this.map.removeAllPin();
    this.map.initMap(this.address.latitude, this.address.longitude, true, true, this.address.radius);
  }

  submit() {

    if (!this.validated) {
      this.validate();
      return;
    }

    const t = this.address;
    t.uuid = this.data.uuid;

    this.isLoading = true;
    this.dataService.post('/post/address/save', t, (res) => {
      if (res.status) {
        this.router.navigate(['/user/ads', this.data.uuid, 'gallery']);
      }
      this.isLoading = false;
    });
  }
}
