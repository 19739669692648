import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.scss'],
})
export class FavouriteComponent implements OnInit {

  public type;
  public listing = [];
  public isLoading = false;

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      this.type = params.type ? params.type : 'properties';
      this.favourite();
    });
  }

  favourite() {
    this.isLoading = true;
    const y = {type: this.type, token: null};
    this.dataService.post('/user/favourite', y, (cb) => {
      this.listing = cb.collection;
      this.isLoading = false;
    });
  }


}
