import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-post-listing',
  templateUrl: './post-listing.component.html',
  styleUrls: ['./post-listing.component.scss'],
})
export class PostListingComponent implements OnInit {

  @Input() title;
  @Input() type;
  @Input() featured = false;
  @Input() filter;
  public page;
  public totalPage = 0;
  public posts;
  public loading = false;

  constructor(
    private dataService: DataService
  ) {
    this.page = 0;
    this.resetListing();
  }

  ngOnInit() {
   this.getCollection();
  }

  resetListing() {
    this.posts = [];
  }

  getCollection() {

    if (!this.type) {
      console.error('Type not found.');
      return;
    }

    this.loading = true;
    this.title = this.type;
    const searchVariable = {type: this.type, featured: this.featured, page: this.page, filter: this.filter, token: null};

    this.dataService.post('/post', searchVariable, (callback) => {
      this.page += 1;
      this.totalPage = callback.listing.totalPages;
      if (this.posts.length === 0) {
        this.posts = callback.listing.collections;
      } else {
        this.posts = this.posts.concat(callback.listing.collections);
      }
      this.loading = false;
      console.log(this.posts, callback, this.totalPage, searchVariable);
    });
  }

  modify() {
    return this.type.replace('_', ' ');
  }
}
