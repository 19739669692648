import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public data = {token: null, username: null, password: null};
  public isLoading = false;
  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {}

  submit() {
    this.isLoading = true;
    this.dataService.post('/user/login', this.data, (rtn) => {
      if (rtn.status) {
        this.dataService.setToken(rtn.data.token);
        this.router.navigate(['/user/dashboard']).then(status => {
          this.isLoading = false;
          this.data = {token: null, username: null, password: null};
        });
      }
      this.isLoading = false;
    });
  }
}
