import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-af-review',
  templateUrl: './af-review.component.html',
  styleUrls: ['./af-review.component.scss'],
})
export class AfReviewComponent implements OnInit {

  public pageLoad = false;
  public submitLoading = false;
  public data = {status: null, uuid: null, title: null, descp: null, address_id: null,
    post_from: null, post_to_days: null, address: null, token: null, images: [], type: null, radius: null, post_package: null};
  public creditDays = [];

  @Input() set setData(dt: any) {

    if (dt.uuid) {
      this.data = dt;

      if (this.data.status === 9 || this.data.status === 1) {
        this.router.navigate(['/user/ads', this.data.uuid, 'introduction']);
      }

      this.pageLoad = true;
      if (this.data.post_from) {
        const d = new Date(this.data.post_from);
        this.data.post_from = {year: d.getFullYear(), month: d.getMonth(), day: d.getDate()};
      } else {
        const d = new Date();
        this.data.post_from = {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};
      }

      this.getCreditDay();
    }
  }

  constructor(private calendar: NgbCalendar, private dataService: DataService, private router: Router) { }

  ngOnInit() {
  }

  getCreditDay() {
    this.dataService.post('/post/get/plan', {type: this.data.type, radius: this.data.radius}, (cb) => {

      if (!cb.status) {
        this.dataService.alert('There is an error retriving plan credit. Please retry.', 'Error');
        return;
      }
      this.creditDays = cb.plan;

      if (!this.data.post_to_days) {
        this.data.post_to_days = 0;
      }
    });
  }

  submit() {

    if (!this.data.post_from) {
      this.dataService.alert('Require Post From', 'danger');
      return;
    }

    if (!this.data.post_to_days && this.data.post_to_days !== 0) {
      this.dataService.alert('Require Post From', 'danger');
      return;
    }

    if (!this.data.address) {
      this.dataService.alert('Location required.', 'danger');
      return;
    }

    this.data.post_package = this.creditDays[this.data.post_to_days];

    if (!this.data.post_package) {
      this.dataService.alert('Invalid post package.', 'danger');
      return;
    }

    if (!this.data.images || this.data.images.length === 0) {
      this.dataService.alert('At least 1 image required.', 'danger');
      return;
    }

    this.submitLoading = true;

    this.dataService.post('/post/submit', this.data, (cb) => {

      if (!cb.status) {
        this.dataService.alert(cb.message, 'danger');
        return;
      }

      this.dataService.alert(cb.message, 'success');

      this.router.navigate(['/user/my-ads', this.data.type]);

    });

  }

  setToday() {
    this.data.post_from = this.calendar.getToday();
  }
}
