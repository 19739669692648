import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-af-gallery',
  templateUrl: './af-gallery.component.html',
  styleUrls: ['./af-gallery.component.scss'],
})
export class AfGalleryComponent implements OnInit {

  public data;
  public images = [];
  public imgPath = '';
  public imageId = null;
  public trashLoading = false;
  public featureLoading = false;

  @Input() set setData(dt: any) {
    if (dt.uuid) {
      this.data = dt;
//      this.images = dt.images;
      this.listImage(true);
    }
  }

  constructor(private dataService: DataService) {
    this.imgPath = environment.imageURL + '/resource/images/small/';
  }

  ngOnInit() {
  }

  listImage(status) {
    const y = {uuid: this.data.uuid, token: null};
    this.dataService.post('/post/get/gallery', y, (res) => {
      this.images = res.images;
    });
  }

  removeImage(imageId, index) {
    this.imageId = imageId;
    this.trashLoading = true;
    const y = {uuid: this.data.uuid, image_id: imageId, token: null};
    this.dataService.post('/post/remove/gallery', y, (res) => {
      if (res.status) {
        this.listImage(true);
      }
      this.trashLoading = false;
    });
  }

  featureImage(imageId, index) {
    this.imageId = imageId;
    this.featureLoading = true;
    const y = {uuid: this.data.uuid, image_id: imageId, token: null};

    this.dataService.post('/post/feature/gallery', y, (res) => {
      if (res.status) {
        const existingIndex = this.images.findIndex((obj => obj.feature === 1));
        if (existingIndex > -1) {
          this.images[existingIndex].feature = 0;
        }
        this.images[index].feature = 1;
      } else {
        this.dataService.alert('Failed to set as feature. Please retry', 'danger');
      }
    });
  }
}
