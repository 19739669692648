import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnInit {

  public conf = {params : {}, system: {}};
  public navbarOpen = false;
  public isLoading = false;

  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private router: Router
  ) { }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
    });
  }

  logout() {
    this.isLoading = true;
    this.apiService.post('/user/logout', {token: this.dataService.getToken()}).then((rtn) => {
      if (rtn.status) {
        this.dataService.removeToken();
        this.router.navigate(['/base']).then(() => {
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  }

}
