import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-my-ads',
  templateUrl: './my-ads.component.html',
  styleUrls: ['./my-ads.component.scss'],
})
export class MyAdsComponent implements OnInit {

  public type;
  public listing = [];
  public isLoading = false;
  public page;
  public totalPages;
  public pageNat = [];

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      this.type = params.type ? params.type : 'properties';
      this.myAds();
    });
  }

  myAds(page = 1) {
    this.isLoading = true;
    this.page = page;

    const c = {type: this.type, self: true, page: this.page, token: null};
    this.dataService.post('/post', c, (cb) => {
      this.totalPages = cb.listing.totalPages;
      this.listing = cb.listing.collections;
      this.isLoading = false;
      this.pageNat = this.pageNatBuilder();
    });
  }

  translate(status) {
    switch(status) {
      case 1:
        return 'Active';
      case 2:
        return 'Draft';
      case 0:
        return 'Post Disabled';
      case 9:
        return 'Pending Verification';
    }
  }

  pageNatBuilder() {

    if (this.totalPages == 1) {
      return [];
    }

    if (this.totalPages <= 5) {
      return Array.from({length: this.totalPages}, (x, i) => i + 1);
    } 

    if (this.page - 2 > 0 && this.page + 2 <= this.totalPages) {
      return Array.from({length: 5}, (x, i) => i + (this.page - 2));
    }

    return Array.from({length: 5}, (x, i) => i + (this.totalPages - 5));
    
  }
}
