import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BaseTemplateComponent } from './template/base-template/base-template.component';
import { AuthTemplateComponent } from './template/auth-template/auth-template.component';
import { HomeComponent } from './base/home/home.component';
import { LoginComponent } from './base/login/login.component';
import { RegisterComponent } from './base/register/register.component';
import { ForgotPasswordComponent } from './base/forgot-password/forgot-password.component';
import { PageComponent } from './base/page/page.component';
import { PostComponent } from './base/post/post.component';
import { PostViewComponent } from './base/post-view/post-view.component';
import { ContactComponent } from './base/contact/contact.component';
import { UserTemplateComponent } from './template/user-template/user-template.component';
import { WalletComponent } from './user/wallet/wallet.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { FavouriteComponent } from './user/favourite/favourite.component';
import { MyAdsComponent } from './user/my-ads/my-ads.component';
import { AdsFormComponent } from './user/ads-form/ads-form.component';
import { IsLoginService } from './guard/is-login.service';
import { NotLoginService } from './guard/not-login.service';

const routes: Routes = [
  {
    path: 'base',
    component: BaseTemplateComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'post/:type',
        component: PostComponent
      },
      {
        path: 'post/detail/:uuid',
        component: PostViewComponent
      },
      {
        path: 'page/:page_key',
        component: PageComponent
      },
      {
        path: 'support',
        component: ContactComponent
      },
    ]
  },
  {
    path: 'auth',
    component: AuthTemplateComponent,
    canActivateChild: [IsLoginService],
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
    ]
  },
  {
    path: 'user',
    component: UserTemplateComponent,
    canActivateChild: [NotLoginService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
        // register as property agent here
        // change password here
      },
      {
        path: 'favourite',
        component: FavouriteComponent
      },
      {
        path: 'favourite/:type',
        component: FavouriteComponent
      },
      {
        path: 'transaction',
        component: LoginComponent
      },
      {
        path: 'wallet',
        component: WalletComponent
      },
      {
        path: 'my-ads',
        component: MyAdsComponent
      },
      {
        path: 'my-ads/:type',
        component: MyAdsComponent
      },
      {
        path: 'ads',
        component: AdsFormComponent
      },
      {
        path: 'ads/:uuid',
        component: AdsFormComponent
      },
      {
        path: 'ads/:uuid/:stage',
        component: AdsFormComponent
      },
    ]
  },
  {
    path: '',
    redirectTo: '/base',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
