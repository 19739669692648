import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import * as Fingerprint2 from 'fingerprintjs2';
import { CookieService } from 'ngx-cookie-service';
import { Geolocation } from '@ionic-native/geolocation/ngx';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public configuration;
  private userConfiguration;
  private userDevice;
  private userData;

  constructor(
    private api: ApiService,
    private router: Router,
    private cookieService: CookieService,
    private geolocation: Geolocation
  ) {
    this.userDevice = {};

  }

  public pageNavigator(path) {
    this.router.navigate(path);
  }

  public async getDeviceFingerPrint(callback) {

    if (this.userDevice.ip) {
      callback(this.userDevice);
      return;
    }

    const getIPDevice = () => {
      this.api.get('https://api.ipify.org/?format=json').then(
        getIPSuccessHandler
      );
    };

    const getIPSuccessHandler = (ipResponse) => {
      this.userDevice.ip = ipResponse.ip;
      getDeviceData();
    };

    const getDeviceData = async () => {
      const components = await Fingerprint2.getPromise();
      const deviceDataResponse = components.map(component => component.value);
      this.userDevice.device = deviceDataResponse;
      callback(this.userDevice);
    };

    await getIPDevice();

  }

  public getUserGeoLocation(callback) {
    this.geolocation.getCurrentPosition().then((resp) => {
      callback(resp.coords);
     }).catch((error) => {
      callback(error);
     });
  }

  public setToken(token) {
    const now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000 * 30);
    this.cookieService.set('_hdtk', token, now, '/');
  }

  public getToken() {
    return this.cookieService.get('_hdtk');
  }

  public refreshToken() {
    this.setToken(this.getToken());
  }

  public removeToken() {
    this.cookieService.deleteAll('/');
    this.userDevice = {};
    this.userConfiguration = undefined;
  }

  public getUserData() {
    return this.userData;
  }

  public setUserData(data) {
    this.userData = data;
  }

  public getUserParam(key, value) {
    this.getUserConfiguration((cb) => {
      value(cb[key] ? cb[key] : null);
    });
  }

  public getHelpdashConfiguration(callback) {

    if (this.configuration) {
      callback(this.configuration);
      return;
    }

    this.api.get('/system/get/configuration').then((succ) => {
      this.configuration = succ;
      callback(this.configuration);
      return;
    });
  }

  public getUserConfiguration(callback) {

    if (!this.getToken()) {
      callback();
      return;
    }

    if (this.userConfiguration) {
      callback(this.userConfiguration);
      return;
    }

    this.api.post('/support/get/user/configuration', {tk: this.getToken()}).then((succ) => {
      this.userConfiguration = succ.configuration;
      callback(this.userConfiguration);
      return;
    });
  }

  public post(url, data, callback, custom = false) {
    data.token = this.getToken();
    this.api.post(url, data).then( (result) => {
      if (result && result.fromStatusTranslation) {
        let statusType = '';
        switch (result.type) {
          case 'warning':
            statusType = 'danger';
            break;
          case 'unchanged':
            statusType = 'warning';
            break;
          default:
            statusType = 'success';
            break;
        }
      }
      if (result && result.message && !result.status) {
        this.alert(result.data, 'error');
      }
      callback(result);
    });
  }

  public get(url, callback) {
    this.api.get(url).then( (result) => {
      callback(result);
    });
  }

  public IURedirect(results, path) {
    if (results.type === 'insert' || results.type === 'update') {
      this.router.navigate([path]);
    }
  }

  public setLastPostSearch(location) {
    localStorage.setItem('lastSearch', JSON.stringify(location));
  }

  public getLastPostSearch(cb) {
    if (localStorage.getItem('lastSearch')) {
      return cb(JSON.parse(localStorage.getItem('lastSearch')));
    }

    return cb(false);
  }

  public alert(message, type = 'error') {
    alert(message);
  }

}
