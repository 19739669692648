import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(private modalService: NgbModal, private api: ApiService, private dataService: DataService) { }
  public changePasswordData = {token: null, password: null, confirm_password: null};
  public agentData = {token: null, agentRENNumber: null, propertyAgencyName: null, propertyAgencyBranch: null};
  public changePasswordLoading = false;
  public propertyAgencyLoading = false;
  public profileUpdateLoading = false;
  public profile = {balance: null, latest: null, is_agent: null, agent_agentRENNumber: null,
    agent_propertyAgencyName: null, agent_propertyAgencyBranch: null, name: null, mobile: null, email: null, create_date: null};
  public plan = [];
  public salePlan = [];
  public histories = [];
  public individualPlan = [];
  public gateway = [];
  public credit = {latest: null, balance: null};
  public currentPage = 1;
  public pageLoop = [];
  public totalPage = 1;
  public selectedCredit = null;
  public paymentMethod = null;
  public awaitingPayment = false;
  public redirectWindow = null;
  public cart_uuid = null;
  public buyCreditLoading = false;
  public paymentCloseFailed = false;
  public paymentCloseSuccess = false;
  private checkTimer;
  public gatewayloading = false;

  ngOnInit() {
    this.getUser();
    this.getPlan('individual', (cb) => {
      this.individualPlan = cb;
    });
    this.getBalance();
    this.getHistory();
    this.getSalesPlan();
  }
 
  getUser() {
    this.dataService.post('/user/get-user', {token: null}, (rtn) => {
      console.log(rtn);
      this.profile = rtn.data;
      if (this.profile.is_agent && (this.profile.is_agent === 1 || this.profile.is_agent === 2)) {
        this.agentData.agentRENNumber = this.profile.agent_agentRENNumber;
        this.agentData.propertyAgencyName = this.profile.agent_propertyAgencyName;
        this.agentData.propertyAgencyBranch = this.profile.agent_propertyAgencyBranch;
      }
      console.log(this.profile.name)
    });
  }

  getPlan(type, callback) {
    const data = {type: null, token: null};
    data.type = type;
    this.dataService.post('/post/get-plan', data, (rtn) => {
      console.log(rtn)
      callback(rtn.data);
    });
  }

  getBalance() {
    this.dataService.post('/wallet/balance', { token: null}, (rtn) => {
      console.log(rtn);
      this.credit = rtn;
    });
  }

  getSalesPlan() {
    this.dataService.post('/wallet/get/plan', { token: null}, (rtn) => {
      this.salePlan = rtn.plan;
    });
  }

  getHistory(page = 1) {
    this.currentPage = page;
    this.dataService.post('/wallet/history', {page: page, token: null}, (rtn) => {
      console.log(rtn);
      this.histories = rtn.collection;
      this.totalPage = rtn.total;


      if (this.totalPage > 5) {
        var start = page - 2;
        
        if (start < 1) {
          start = 1;
        }

        var end = start + 4;

        if (end > this.totalPage) {
          end = this.totalPage;
          start = end - 4;
        }

        this.pageLoop = this.range(start, end);

      } else {
        this.pageLoop = this.range(1, this.totalPage);
      }

    });
  }
  
  range(start, end) {
    return Array(end - start + 1).fill(0).map((_, idx) => start + idx);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal'}).result.then((result) => {
    }, (reason) => {
      this.resetBuyCredit();
    });
  }

  submit() {
    this.changePasswordLoading = true;
    this.dataService.post('/user/change-password', this.changePasswordData, (rtn) => {
      console.log(rtn);
      if (rtn.status) {
        this.changePasswordData = {token: null, password: null, confirm_password: null};;
        this.modalService.dismissAll();
        this.dataService.alert('Password Successfully Update.');
      }
      this.changePasswordLoading = false;
    });
  }

  submitAgent() {
    this.propertyAgencyLoading = true;
    this.dataService.post('/user/register-agent', this.agentData, (rtn) => {
      if (rtn.status) {
        this.modalService.dismissAll();
      }
      this.propertyAgencyLoading = false;
    });
  }

  submitProfile() {
    this.profileUpdateLoading = true;
    const p = {name: this.profile.name, mobile: this.profile.mobile}
    this.dataService.post('/user/update', p, (rtn) => {
      if (rtn.status) {
        this.dataService.alert('Profile Update Successfully.', 'Profile Update');
        this.modalService.dismissAll();
      }
      this.profileUpdateLoading = false;
    });
  }

  selectedCreditFunc(plan) {
    this.selectedCredit = plan;
    this.gatewayloading = true;
    this.dataService.post('/wallet/payment/method', {token: null}, (rtn) => {
      this.gateway = rtn.collection;
      this.gatewayloading = false;
    });
  }

  buyCreditFunc() {

    this.buyCreditLoading = true;
    const dt = {credit_uuid: this.selectedCredit.uuid, payment_method: this.paymentMethod, token: null};
    this.dataService.post('/wallet/buy', dt, (rtn) => {
      console.log(rtn);
      if (rtn.action === 'redirect') {
        this.cart_uuid = rtn.cart_uuid;
        this.awaitingPayment = true;
        this.redirectWindow = window.open(rtn.url.replaceAll('\'', ''));
        const timer = setInterval(() => {
          if (this.redirectWindow.closed) {
              this.dataService.post('/wallet/payment/status', {status: 0, cart_uuid: this.cart_uuid}, (cb) => {
                clearInterval(timer);
                this.paymentCloseFailed = true;
                this.stopCheck();
              });
          }
        }, 5000);
        this.check();
        this.buyCreditLoading = false;
      }

    });
  }

  resetBuyCredit() {
    this.selectedCredit = null;
    this.paymentMethod = null;
    this.awaitingPayment = false;
    this.paymentCloseFailed = false;
  }

  check() {
    this.checkTimer = setInterval(() => {
      this.dataService.post('/wallet/check', {cart_uuid: this.cart_uuid}, (rtn) => {
        if (rtn.status && (rtn.failed || rtn.success)) {
          this.stopCheck();

          if (rtn.failed) {
            this.paymentCloseFailed = true;
          }

          if (rtn.success) {
            this.paymentCloseSuccess = true;
          }
        }
      });
    }, 5000);
  }

  stopCheck() {
    clearInterval(this.checkTimer);
  }
}
