import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-af-detail',
  templateUrl: './af-detail.component.html',
  styleUrls: ['./af-detail.component.scss'],
})
export class AfDetailComponent implements OnInit {

  public isLoading = false;
  public data;
  public options = [];

  @Input() set setData(dt: any) {
    if (dt.uuid) {
      this.data = dt;
      this.getGroup();
    }
  }

  @Input() editable: boolean;

  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {
  }

  getGroup() {
    this.isLoading = true;
    const c = {type: this.data.type, token: null};
    this.dataService.post('/post/group-option', c, (cb) => {
      this.isLoading = false;
      
      if (this.data.options.length > 0) {
        const option_child = this.data.options.map(s => s.child.map(y => y.id));
        let selectedOption = [].concat.apply([], option_child);

        for (let x = 0; x < cb.length; x++) {
          const current = cb[x];
          for (let y = 0; y < current.option.length; y++) {
            if (selectedOption.indexOf(current.option[y].id) > -1) {
              cb[x].option[y].checked = true;
            }
          }
        }
      }
  
      this.options = cb;
    });
  }

  selected(group, current) {
    const currentArr = this.options[group];

    const currentMax = currentArr.max;
    const currentSelected = currentArr.option.filter( (obj) => {
      return obj.checked && obj.checked > 0;
    });

    if (currentMax < currentSelected.length) {
      this.dataService.alert('You could only select up to ' + currentMax, 'danger');
      delete currentArr.option[current].checked;
    }

  }

  submit() {

    let v = this.options.map(a => a.option);
    v = [].concat.apply([], v);
    const selected = v.filter( (obj) => {
      return obj.checked;
    });

    this.isLoading = true;
    const selectedIDs = selected.map(a => a.id);
    const c = {uuid: this.data.uuid, token: null, option: selectedIDs};

    this.dataService.post('/post/group-option/save', c, (cb) => {
      this.isLoading = false;
      if (cb.status) {
        this.router.navigate(['/user/ads', this.data.uuid, 'location']);
      }
    });
    
  }
}
