import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss'],
})
export class PostViewComponent implements OnInit {

  public conf = {params : {service_type_detail : []}, system: {}};
  public location = {type: null};
  public paramsSubscription;
  public post = {call_code: null, mobile_no: null, title: null, address: null, options: null, detail: null, descp: null};
  public title;
  public subtitle;
  public displayContact;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
      // tslint:disable-next-line:prefer-for-of
      for (let x = 0; x < this.conf.params.service_type_detail.length; x++) {
        if (typeof this.conf.params.service_type_detail[x].value !== 'object') {
          this.conf.params.service_type_detail[x].value = JSON.parse(this.conf.params.service_type_detail[x].value);
        }
      }

      console.log(this.conf);
    });
  }

  ionViewDidEnter() {

    this.dataService.getLastPostSearch((search) => {
      if (search) {
        this.location = search;
      }
    });

  }

  ngOnInit() {
    this.paramsSubscription = this.activatedRoute.params.subscribe( params => {
      this.getDetail(params.uuid);
    });
  }

  getDetail(uuid) {

    const dt = {uuid: null, token: null};
    dt.uuid = uuid;
    const tk = this.dataService.getToken();
    if (tk) {
      dt.token = tk;
    }
    this.dataService.post('/post/detail', dt, (cb) => {
      this.post = cb;
      this.parseJSONObject('address');
      this.parseJSONObject('options');
      this.title = cb.type === 'properties' ? cb.title : cb.company_name;
      this.subtitle = cb.type === 'properties' ? cb.property_type : cb.title + ' - ' + cb.property_type;
      console.log(cb);
    });
  }

  parseJSONObject(collection) {
    if (typeof this.post[collection] !== 'object') {
      this.post[collection] = JSON.parse(this.post[collection]);
    }
  }


  getWhatsapp() {
    let url = 'https://api.whatsapp.com/send?phone=' + this.post.call_code.replace('+', '') + this.post.mobile_no.replace('-', '') 
    url += '&text=';
    url += encodeURIComponent('Hi, I found your ads from Helpdash.tech and would like to check with you pertaining to ' + this.post.title);
    window.open(url);
  }
  
  showContact() {
    this.displayContact = true;
  }

  setLocation(location) {
    this.location = location;
  }
}
