import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  /**
   * API URL
   */

  private apiURL: string = environment.apiURL;

  /**
   * Http Options
   */

  public status;
  public response;
  public error;

  private httpOptions: object = {
    observe: 'response',
    responseType: 'json'
  };

  private pathBuilder(path) {
    if (!path.includes('http://') && !path.includes('https://')) {
      path = this.apiURL + path;
    }
    return path;
  }

  private optionsBuilder(options) {
    if (options) {
      return options;
    }
    return this.httpOptions;
  }

  constructor(private http: HttpClient, private router: Router) { }

  get(path, options?): any {
    return new Promise((resolve?) => {

      path = this.pathBuilder(path);
      options = this.optionsBuilder(options);

      this.http.get(path, options).subscribe(

        (rtn: object) => {

          /**
           * Set Public Status & Response Variables
           */
          const statusKey = 'status';
          const bodyKey = 'body';
          this.status   = rtn[statusKey];
          this.response = rtn[bodyKey];

          /**
           * Success Callback
           */
          resolve(rtn[bodyKey]);
        },

        (err: HttpErrorResponse) => {

          /**
           * Set Public Status & Error Variables
           */
          const statusKey = 'status';
          const errorKey = 'error';
          this.status   = err[statusKey];
          this.error = err[errorKey];

          /**
           * Error Callback
           */
          resolve(err[errorKey]);
        //  this.router.navigate(['/']);
        }

      );

    });
  }

  post(path, val, options?): any {
    return new Promise((resolve?) => {

      path = this.pathBuilder(path);
      options = this.optionsBuilder(options);

      this.http.post(path, val, options).subscribe(

        (rtn) => {

          /**
           * Set Public Status & Response Variables
           */
          const statusKey = 'status';
          const bodyKey = 'body';
          this.status   = rtn[statusKey];
          this.response = rtn[bodyKey];

          /**
           * Success Callback
           */
          resolve(rtn[bodyKey]);
        },

        (err: HttpErrorResponse) => {

          /**
           * Set Public Status & Error Variables
           */
          const statusKey = 'status';
          const errorKey = 'error';
          this.status   = err[statusKey];
          this.error = err[errorKey];

          /**
           * Error Callback
           */
          resolve(err[errorKey]);
          console.log(err, path);
          // this.router.navigate(['/']);

        }
      );
    });
  }

  directPost(path, val, options?): any {

    path = this.pathBuilder(path);
    options = this.optionsBuilder(options);

    return this.http.post(path, val, options);
  }

  postBLOB(path, val): any {
    path = this.pathBuilder(path);
    return this.http.post(path, val, {responseType: 'blob'});
  }

}
