import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { BaseTemplateComponent } from './template/base-template/base-template.component';
import { AuthTemplateComponent } from './template/auth-template/auth-template.component';
import { HeaderComponent } from './base/component/header/header.component';
import { FooterComponent } from './base/component/footer/footer.component';
import { HomeComponent } from './base/home/home.component';
import { MenuComponent } from './base/component/menu/menu.component';
import { SearchComponent } from './base/component/search/search.component';
import { PageComponent } from './base/page/page.component';
import { LoginComponent } from './base/login/login.component';
import { RegisterComponent } from './base/register/register.component';
import { ForgotPasswordComponent } from './base/forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ProductServiceDisplayComponent } from './base/component/product-service-display/product-service-display.component';
import { PostComponent } from './base/post/post.component';
import { PostListingComponent } from './base/component/post-listing/post-listing.component';
import { PostDetailComponent } from './base/component/post-detail/post-detail.component';
import { ContactComponent } from './base/contact/contact.component';
import { PostRefineSearchComponent } from './base/component/post-refine-search/post-refine-search.component';
import { ContactFormComponent } from './base/component/contact-form/contact-form.component';
import { PostViewComponent } from './base/post-view/post-view.component';
import { UserTemplateComponent } from './template/user-template/user-template.component';
import { CartComponent } from './base/cart/cart.component';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { WalletComponent } from './user/wallet/wallet.component';
import { UserHeaderComponent } from './user/components/user-header/user-header.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { FavouriteComponent } from './user/favourite/favourite.component';
import { ScrollToTopComponent } from './base/component/scroll-to-top/scroll-to-top.component';
import { MyAdsComponent } from './user/my-ads/my-ads.component';
import { AdsFormComponent } from './user/ads-form/ads-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { NgxEditorModule } from 'ngx-editor';
import { EditorConfigService } from './config/editor-config.service';
import { AfIntroComponent } from './user/ads-form/af-intro/af-intro.component';
import { AfDetailComponent } from './user/ads-form/af-detail/af-detail.component';
import { AfGalleryComponent } from './user/ads-form/af-gallery/af-gallery.component';
import { AfLocationComponent } from './user/ads-form/af-location/af-location.component';
import { AfReviewComponent } from './user/ads-form/af-review/af-review.component';
import { DropzoneComponent } from './user/components/dropzone/dropzone.component';
import { ProgressComponent } from './user/components/progress/progress.component';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MapComponent } from './user/components/map/map.component';
import { CookieService } from 'ngx-cookie-service';

library.add(fas, faEllipsisV);
@NgModule({
  declarations: [
    AppComponent,
    BaseTemplateComponent,
    AuthTemplateComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MenuComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    PageComponent,
    SearchComponent,
    ProductServiceDisplayComponent,
    PostComponent,
    PostListingComponent,
    PostDetailComponent,
    ContactComponent,
    PostRefineSearchComponent,
    ContactFormComponent,
    PostViewComponent,
    CartComponent,
    UserTemplateComponent,
    WalletComponent,
    UserHeaderComponent,
    DashboardComponent,
    FavouriteComponent,
    ScrollToTopComponent,
    MyAdsComponent,
    AdsFormComponent,
    AfIntroComponent,
    AfDetailComponent,
    AfGalleryComponent,
    AfLocationComponent,
    AfReviewComponent,
    DropzoneComponent,
    ProgressComponent,
    MapComponent
  ],
  entryComponents: [
    PostRefineSearchComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgbModule,
    FontAwesomeModule,
    LoadingBarModule,
    LoadingBarRouterModule,
  ],
  providers: [
    DatePicker,
    StatusBar,
    SplashScreen,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    EditorConfigService,
    LoadingBarService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
