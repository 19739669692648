import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit {

  @Input() post;
  public displayContact = false;

  constructor() { }

  ngOnInit() {}

  getKeyObject(collection, key) {
    if (typeof this.post[collection] === 'object') {
      this.post[collection] = JSON.parse(this.post[collection]);
    }

    console.log(this.post[collection][key])
    return this.post[collection][key];
  }

  getTypeVariable() {
    switch (this.post.type) {
      case 'properties':
        if (this.post.listing_type === 'Sell') {
          return 'Sell at only';
        }
        return 'Rent at only';
      case 'services':
        return 'Services from';
      case 'vacancies':
        return 'Salary from';
    }
  }

  getPaymentType() {
    switch (this.post.type) {
      case 'properties':
        if (this.post.listing_type === 'Sell') {
          return '';
        }
        return '/Mo';
      case 'services':
        return '/Serv';
      case 'vacancies':
        return '/' + this.post.charge_type;
    }
  }

  getWhatsapp() {
    let url = 'https://api.whatsapp.com/send?phone=' + this.post.call_code.replace('+', '') + this.post.mobile_no.replace('-', '') 
    url += '&text=';
    url += encodeURIComponent('Hi, I found your ads from Helpdsah.tech and would like to check with you pertaining to ' + this.post.title);
    window.open(url);
  }

  showContact() {
    this.displayContact = true;
  }

  trim(data) {
    return data.substring(0, 150) + '...';
  }
}
