import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-af-intro',
  templateUrl: './af-intro.component.html',
  styleUrls: ['./af-intro.component.scss'],
})
export class AfIntroComponent implements OnInit {

  @Input() set setData(dt: any) {
    if (dt.uuid) {
      this.data = dt;
      if (this.data.available_date) {
        const d = new Date(this.data.available_date);
        this.data.available_date = {year: d.getFullYear(), month: d.getMonth(), day: d.getDate()};
      }
      this.step = 2;
    }
  }
  public data;
  public isLoading = false;
  step: any;
  @Input() editable: boolean;

  constructor(private calendar: NgbCalendar, private dataService: DataService, private router: Router) {
    this.reset();
   }

  ngOnInit() {
  }

  reset() {
    let type = null;
    if (this.data && this.data.type) {
      type = this.data.type;
    }
    this.data = {available_date: null, type, token: null};
  }

  setToday() {
    this.data.available_date = this.calendar.getToday();
  }

  next(step) {
    this.step = step;
  }

  submit() {
    this.isLoading = true;
    this.dataService.post('/post/insert', this.data, (cb) => {

      this.isLoading = false;
      if (cb.status) {
        this.router.navigate(['/user/ads', cb.uuid, 'detail']);
      }
    });
  }
}
