import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {

  public files = [];
  public supportedFileTypeString = null;
  @Input() supportedFileType = [];
  @Input() data = {uuid: null};
  @Output() complete = new EventEmitter<boolean>();
  private isUpload = false;
  private progressInterval;
  @ViewChild('fileDropRef', {static: false}) fileDropRef;

  constructor(private apiService: ApiService, private dataService: DataService) { }

  ngOnInit() {
    if (this.supportedFileType.length > 0) {
      this.supportedFileTypeString = 'Supported ' + this.supportedFileType.join(' , ')
      .replace(/image\/|application\/|text\//g, '') + ' only';
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }


  @HostListener('drop', ['$event']) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.prepareFilesList(files);
    }
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;

      if (this.supportedFileType.indexOf(item.type) === -1) {
        item.progress = 100;
        item.notSupported = true;
      }

      this.files.push(item);
    }
    this.fileDropRef.nativeElement.value = '';

    if (!this.isUpload) {
      this.uploadFiles(0);
    }
  }

  fileBrowserHandler(files) {
    this.prepareFilesList(files);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      this.dataService.alert('Upload in progress. Failed could not be deleted.', 'danger');
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFiles(index: number) {
    if (index === this.files.length) {
      this.isUpload = false;
      return;
    }

    this.isUpload = true;

    if (this.files[index].progress === 100 || this.files[index].notSupported) {
      this.uploadFiles(index + 1);
      return;
    }

    const object = {
      enctype: 'application/x-www-form-urlencoded',
      accept: 'application/json',
      observe: 'response',
      responseType: 'json'
    };

    const formData: FormData = new FormData();
    formData.append('uploadFile', this.files[index], this.files[index].name);
    formData.append('uuid', this.data.uuid);
    formData.append('token', this.dataService.getToken());
    const path = '/system/upload'; // + encodeURIComponent(this.dataService.getToken());
    this.apiService.post(path, formData, object).then((res) => {

      if (!res.status) {
        this.files[index].error = res.message;
      } else {
        this.files[index].progress = 100;
        this.complete.emit(true);
      }

      clearInterval(this.progressInterval);
      this.uploadFiles(index + 1);
    });

    this.progressInterval = setInterval(() => {
      this.apiService.get('/system/upload/status').then((cb) => {
        this.files[index].progress = cb;
      });
    }, 1000);
  }
}
