import { Component, OnInit } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-ads-form',
  templateUrl: './ads-form.component.html',
  styleUrls: ['./ads-form.component.scss'],
})
export class AdsFormComponent implements OnInit {

  public uuid;
  public stage;
  public data = {status: null};
  public editable = true;

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      if (params.uuid) {
        this.uuid = params.uuid;
        this.getPost(params.uuid);
      }
      const availableStage = ['introduction', 'detail', 'location', 'gallery', 'review'];
      this.stage = params.stage || availableStage.indexOf(params.stage) === -1 ? params.stage : 'introduction';
    });
  }

  getPost(uuid) {
    const t = {uuid, self: true, token: null};
    this.dataService.post('/post/detail', t, (cb) => {
      this.data = cb;
      if ([0, 1].indexOf(this.data.status) > -1) {
        this.editable = false;
      }
      console.log(cb);
    });
  }

}

/*  [toolbar]="[
  ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'justify left', 'justify center', 'justify right', 'justify', 'indent', 'outdent' , 'unordered list', 'ordered list' 'ordered_list', 'bullet_list',{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ['ordered_list', 'bullet_list'],
  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }]
]" */
  