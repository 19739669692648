/// <reference types="@types/googlemaps" />
declare let google: any;
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild('mapWrapper', {static: true}) gmapElement: any;
  @Input() title;
  @Output() updateCoordinate = new EventEmitter<object>();
  private coordinate = {latitude: null, longitude: null, radius: null};
  private map: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  private circle: google.maps.Circle;

  constructor() { }

  ngOnInit() { }

  public initMap(latitude?, longitude?, dropPin?, drawRadius?, radius?) {

    if (!latitude || !longitude) {
      return;
    }

    if (this.map) {
      this.setMap(latitude, longitude, dropPin, drawRadius, radius);
      return;
    }

    const LatLng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};

    const mapProp = {
      center: LatLng,
      zoom: 14,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scaleControl: true,
      zoomControl: true,
    };

    if (!this.gmapElement || !this.gmapElement.nativeElement) {
      return;
    }

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    if (dropPin) {
      this.dropPin(latitude, longitude, drawRadius, radius);
    }

  }

  public setMap(latitude, longitude, dropPin?, drawRadius?, radius?) {

    if (!latitude || !longitude) {
      return;
    }

    const LatLng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};

    this.map.setCenter(LatLng);

    if (dropPin) {
      this.dropPin(latitude, longitude, drawRadius, radius);
    }

  }

  public dropPin(latitude, longitude, drawRadius?, radius?) {
    const LatLng = {lat: parseFloat(latitude), lng: parseFloat(longitude)};

    const marker = new google.maps.Marker({
      position: LatLng,
      map: this.map,
      label: 'Click & Drag',
      draggable: true,
    });

    google.maps.event.addListener(marker, 'dragend', (mark) => {
      const latLng = mark.latLng;
      this.coordinate.latitude = latLng.lat();
      this.coordinate.longitude = latLng.lng();
      this.updateCoordinate.emit(this.coordinate);
    });

    this.markers.push(marker);

    if (drawRadius) {
      this.drawRadius(radius);
    }
  }

  public drawRadius(radius) {

    this.clearRadius();

    this.circle = new google.maps.Circle({
      map: this.map,
      radius: (radius * 1000),
      fillColor: '#007bff',
      strokeColor: '#007bff',
      strokeWidth: 5
    });

    for (const marker of this.markers) {
      this.circle.bindTo('center', marker, 'position');
    }
  }

  public removeAllPin() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers = [];
  }

  public clearRadius() {
    if (this.circle) {
      this.circle.setMap(null);
      this.circle = null;
    }
  }

}
