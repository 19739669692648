import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public conf = {params : {service_type_detail : []}, system: {}};
  public location = {};

  constructor(
    private dataService: DataService
  ) {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
      // tslint:disable-next-line:prefer-for-of
      for (let x = 0; x < this.conf.params.service_type_detail.length; x++) {
        if (typeof this.conf.params.service_type_detail[x].value !== 'object') {
          this.conf.params.service_type_detail[x].value = JSON.parse(this.conf.params.service_type_detail[x].value);
        }
      }
    });
  }

  ngOnInit() {
    this.dataService.getLastPostSearch((search) => {
      if (search) {
        this.location = search;
      }
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  setLocation(location) {
    this.location = location;
  }

}
