import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  public conf = {params : {}, system: {}};
  public navbarOpen = false;
  public isLoading = false;

  constructor(
    private dataService: DataService,
    private router: Router
  ) { }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
    });
  }

  navigate() {
    this.isLoading = true;
    if (this.dataService.getToken()) {
      this.router.navigate(['/user/dashboard']).then(() => {
        this.isLoading = false;
      });
    } else {
      this.router.navigate(['/auth/login']).then(() => {
        this.isLoading = false;
      });
    }
  }
}
