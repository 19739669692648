import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  public data;
  public isLoading = false;

  constructor(private dataService: DataService) {
    this.data = {};
  }

  ngOnInit() {}

  submit() {
    this.isLoading = true;
    this.dataService.post('/user/reset-password', this.data, reply => {
      this.isLoading = false;
      this.dataService.alert('You will receive a new password via email if your email address is register with us.');
      this.data = {};
    });
  }
}
