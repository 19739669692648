import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { environment } from '../../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { PostRefineSearchComponent } from '../post-refine-search/post-refine-search.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  @Input() text = 'light';

  @Input() set location(val) {
    console.log(val)
    if (val) {
      this.searchAddress = val;
    }
    if (val && val.search) {
      this.searchString = val.search;
    }
    if (val && val.formattedAddress) {
      this.searchResult = val.formattedAddress;
    }

    this.type = val.type;  
  }

  @Output() locationEmitter = new EventEmitter();

  public type ;
  public searchString;
  public searchAddress : any = {};
  public searchResult;
  public conf = {params : {service_type: []}, system: {}};
  public serviceType = [];
  public filter = {};
  constructor(
    private dataService: DataService,
    public modalController: ModalController,
    private router: Router
  ) {
    this.location = {};
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
      this.serviceType = cb.params.service_type;
      this.type = this.serviceType[0].value;
    });
  }
 
  ngOnInit() {
    this.searchAddress = this.location;
  }

  getGeoLocation() {
    this.dataService.getUserGeoLocation((cb) => {

      if (!environment.production) {
        cb.latitude = 3.1036075854956637;
        cb.longitude = 101.68364268491932;
      }

      if (!cb.latitude || !cb.longitude) {
        alert('There was an error while trying to get your current location. Error: ' + cb.message);
        return;
      }

      const x = { search: {lat: cb.latitude, lon: cb.longitude, filter: this.filter}, token: null};
      this.dataService.post('/system/get/address/by/coordinate', x, (address) => {
        this.searchString = address.formattedAddressBuild;
        const history = this.searchAddress;
        history.type = this.type;
        this.dataService.setLastPostSearch(history);
        this.router.navigate(['/base/post', this.type], { state: { location: this.searchAddress } });
        this.locationEmitter.emit(address);
      });

    });
  }

  getLocationByKeyword(callback) {

      if (!this.searchString) {
        alert('Search Location could not be empty');
        callback(false);
        return;
      }

      this.dataService.post('/system/get/address/by/keyword', { search: this.searchString, filter: this.filter, token: null}, (address) => {
        // this.searchAddress = address;
        // this.searchString = address.search;
        this.locationEmitter.emit(address);
        callback(true);
      });

  }

  search() {
    this.getLocationByKeyword((cb) => {
      const history = this.searchAddress;
      history.type = this.type;
      this.dataService.setLastPostSearch(history);
      this.router.navigate(['/base/post', this.type], { state: { location: this.searchAddress } });
    });
  }

  async refine() {
    this.filter = {};

    const modal = await this.modalController.create({
      component: PostRefineSearchComponent,
      componentProps: { type: this.type }
    });

    modal.onDidDismiss().then( (data) => {

      if (!data.role && data) {
        this.filter = data.data.filter;
        console.log('MODAL DATA', data.data.filter);
      }

    });

    return await modal.present();
  }
}
