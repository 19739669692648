import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  public data = { token: null, agentRENNumber: null, propertyAgencyName: null, propertyAgencyBranch: null, name: null, username: null, 
    mobile: null, password: null, confirm_password: null, is_agent: null};
  public isLoading = false;

  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit() {}

  submit() {
    this.isLoading = true;
    this.dataService.post('/user/register', this.data, (rtn) => {
      console.log(rtn);
      if (rtn.status) {
        this.dataService.setToken(rtn.data.token);
        this.router.navigate(['/user/dashboard']).then(status => {
          this.isLoading = false;
          this.data = { token: null, agentRENNumber: null, propertyAgencyName: null, propertyAgencyBranch: null, name: null, username: null, 
            mobile: null, password: null, confirm_password: null, is_agent: null};
        });
      }
      this.isLoading = false;
    });
  }
}
