import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class IsLoginService implements CanActivateChild {

  constructor(private api: ApiService, private data: DataService, private router: Router) { }
  // tslint:disable-next-line:max-line-length
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const token = this.data.getToken();

    if (!this.data.getToken()) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => {
      this.api.post('/user/validate', {token}).then(res => {
        if (!res.status) {
          this.data.removeToken();
          return resolve(true);
        }

        this.router.navigate(['/user/dashboard']);
        return resolve(false);
      });
    });

  }
}
