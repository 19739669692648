import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy {

  private paramsSubscription: Subscription;
  public page = {descp: null, title: null, subtitle: null, background: null};

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.paramsSubscription = this.activatedRoute.params.subscribe( params => {
      console.log(params.page_key);
      this.page = {descp: null, title: null, subtitle: null, background: null};
      this.loadPage(params.page_key);
    });
  }

  ngOnDestroy() {
    console.log('page destroy');
    this.paramsSubscription.unsubscribe();
  }

  loadPage(pageKey) {
    const c = {pageKey, token: null}
    this.dataService.post('/system/get/page', c, (cb) => {
      this.page = cb;
      console.log(cb);
    });
  }
}
