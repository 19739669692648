import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { DatePicker } from '@ionic-native/date-picker/ngx';

@Component({
  selector: 'app-post-refine-search',
  templateUrl: './post-refine-search.component.html',
  styleUrls: ['./post-refine-search.component.scss'],
})
export class PostRefineSearchComponent implements OnInit {

  public filterlist = [];

  public filter = {available : null, radius: null, from: null, to: null, offer: null, type: null};
  public type;
  public options;
  constructor(public params: NavParams, private dataService: DataService, private modalCtrl: ModalController, private datePicker: DatePicker) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.type = this.params.get('type');

    if (this.type !== 'room_rental') {
      this.getOptions();
    }
  }

  getOptions() {
    const y = {type: this.type};
    this.dataService.post('/system/get/options', y, (cb) => {
      console.log(cb);
      this.options = cb;
    });
  }

  dismiss() {
    const dt = this.filter;

    this.modalCtrl.dismiss({
      dismissed: true,
      filter: dt
    });
  }

  availableDate() {
    this.datePicker.show({
      date: new Date(),
      mode: 'date',
      androidTheme: this.datePicker.ANDROID_THEMES.THEME_HOLO_DARK
    }).then(
      date => (this.filter.available = date),
      err => console.log('Error occurred while getting date: ', err)
    );
  }

  filterLabel(type) {
    const index = this.filterlist.indexOf(type);

    if (index > -1) {
      this.filterlist.splice(index, 1);
    } else {
      this.filterlist.push(type);
    }

    console.log(this.filterlist);
  }

}
