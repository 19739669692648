import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-service-display',
  templateUrl: './product-service-display.component.html',
  styleUrls: ['./product-service-display.component.scss'],
})
export class ProductServiceDisplayComponent implements OnInit {

  @Input() data: {value: {image: null, reverse: null, title: null, desc: null, path: null}};

  constructor() {
  }

  ngOnInit() {}

}
