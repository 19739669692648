import { Component, Inject, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  public conf = {params : {}, system: {}};
  public windowScrolled = true;
  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.dataService.getHelpdashConfiguration((cb) => {
      this.conf = cb;
    });
  }
  
  scroll() {
  }

}
